import React from 'react';
import { logErrorToTeams } from './helpers';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logErrorToTeams(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return <p style={{ textAlign: 'center' }}>Something went wrong with this component</p>;
    }

    return this.props.children;
  }
}
