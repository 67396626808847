import React from 'react';
import { Icon, Title } from '../index';
import * as styles from './ListItem.module.scss';

export default function ListItem({ icon, count, text }) {
  return (
    <div className={styles.listItem}>
      {icon && <Icon name={icon} className={styles.listIcon} />}
      {count && (
        <Title className={styles.count} size="h2">
          {count}
        </Title>
      )}
      <p className={styles.listText}>{text}</p>
    </div>
  );
}
