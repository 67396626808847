import { getTeamsWebhookUrl, getBuildEnvironment } from 'utils/environment';

export const logErrorToTeams = async (error, componentStack) => {
  const buildEnv = getBuildEnvironment();
  const webhookUrl = getTeamsWebhookUrl();

  const formatted_Card_Payload = {
    type: 'message',
    attachments: [
      {
        contentType: 'application/vnd.microsoft.card.adaptive',
        contentUrl: null,
        content: {
          type: 'AdaptiveCard',
          $schema: 'https://adaptivecards.io/schemas/adaptive-card.json',
          version: '1.5',
          targetWidth: 'Wide',
          body: [
            {
              type: 'TextBlock',
              style: 'heading',
              size: 'Large',
              weight: 'Bolder',
              text: `There is a new error on ${buildEnv} stand:`,
              wrap: true,
            },
            {
              type: 'TextBlock',
              text: error?.toString() ?? 'No error text available',
              wrap: true,
            },
            {
              type: 'CodeBlock',
              separator: true,
              codeSnippet: componentStack,
            },
          ],
        },
      },
    ],
  };

  await fetch(webhookUrl, {
    method: 'POST',
    body: JSON.stringify(formatted_Card_Payload),
    redirect: 'follow',
    mode: 'no-cors',
  });
};
