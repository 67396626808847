import { usePhoneNumbers } from 'staticQueries/phone-numbers';
import { useIsSsr } from 'utils/hooks';
import { getCountryCode } from 'utils/localisation';

export default function LocalisedPhone({ defaultLocale = 'en', children }) {
  const phoneNumbers = usePhoneNumbers();
  const defaultPhone = phoneNumbers.find(({ isDefault }) => isDefault).phone;

  const getPhoneByLocale = locale => {
    const normalizeCountryCodes = countries => countries?.map(country => country.toLowerCase());
    const isLocaleMatch = ({ countries }) => normalizeCountryCodes(countries)?.includes(locale);

    return phoneNumbers.find(isLocaleMatch)?.phone;
  };

  const getPreparedPhone = () => {
    if (defaultLocale === 'de' || defaultLocale === 'az') {
      return getPhoneByLocale(defaultLocale) || defaultPhone;
    }
    if (defaultLocale === 'ae') {
      return getPhoneByLocale(defaultLocale) || defaultPhone;
    }

    if (defaultLocale === 'uk') {
      return getPhoneByLocale(defaultLocale) || getPhoneByLocale('gb') || defaultPhone;
    }

    return (
      getPhoneByLocale(getCountryCode()?.toLowerCase()) ||
      getPhoneByLocale(defaultLocale) ||
      defaultPhone
    );
  };

  if (useIsSsr()) {
    return null;
  }

  return children(getPreparedPhone());
}
