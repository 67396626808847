const CONSENT_COOKIE_NAME = 'consent_status';
const CONSENT_MODAL_ID = 'consentModal';

const getCookieOptions = () => {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 3);
  const domainLang = (() => {
    const lang = process.env.GATSBY_LANG;

    switch (lang) {
      case 'de':
        return 'de';
      case 'ae':
        return 'ae';
      case 'az':
        return 'az';
      case 'uk':
        return 'co.uk';
      default:
        return 'com';
    }
  })();

  const domain =
    window.location.hostname === 'localhost' ? 'localhost' : `andersenlab.${domainLang}`;

  return { expires, domain };
};

export { CONSENT_COOKIE_NAME, CONSENT_MODAL_ID, getCookieOptions };
